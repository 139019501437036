<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_form_sitio_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar listado Sitios</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="nombre" class="col-md-5">Nombre</label>

                    <input
                      type="text"
                      id="nombre"
                      v-model="filtros.nombre"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="sigla" class="col-md-5">Sigla</label>

                    <input
                      type="text"
                      id="sigla"
                      v-model="filtros.sigla"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="codigo" class="col-md-5">Codigo</label>
                    <input
                      type="text"
                      id="codigo"
                      v-model="filtros.codigo"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="ciudad_id" class="col-md-5">Ciudad</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ciudad"
                      placeholder="Ciudades"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.ciudades"
                      :filterable="true"
                      @input="getSelectCiudades()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="departamento_id" class="col-md-5"
                      >Departamento</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="departamento"
                      placeholder="Departamentos"
                      label="descripcion"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.departamentos"
                      :filterable="true"
                      @input="getSelectDepartamentos()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="bloque_id" class="col-md-5">Bloques</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="bloque"
                      placeholder="Bloques"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.bloques"
                      :filterable="true"
                      @input="getSelectBloques()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="tipo_sitio" class="col-md-5"> Tipo Sitio</label>
                    <select
                      id="tipo_sitio"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_sitio"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_sitio in listasForms.tipos_sitio"
                        :key="tipo_sitio.numeracion"
                        :value="tipo_sitio.numeracion"
                      >
                        {{ tipo_sitio.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="estado1" class="col-md-5"> Estado</label>
                    <select
                      id="estado"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nombre1"
                              v-model="form.nombre"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="nombre1"
                              >Nombre</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="sigla"
                              v-model="form.sigla"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="sigla"
                              >Sigla</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="codigo1"
                              v-model="form.codigo"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="codigo1"
                              >Código</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="ciudad_id"
                              v-model="form.ciudad_id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="ciudad_id"
                              >Ciudad</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="departamento_id"
                              v-model="form.departamento_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="departamento_id"
                              >Departamento</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="bloque_id"
                              v-model="form.bloque_id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="bloque_id"
                              >Bloque</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="dirreccion"
                              v-model="form.dirreccion"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="dirreccion"
                              >Dirrección</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="latitud"
                              v-model="form.latitud"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="latitud"
                              >Latitud</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="longitud"
                              v-model="form.longitud"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="longitud"
                              >Longitud</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_sitio1"
                              v-model="form.tipo_sitio"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_sitio1"
                              >Tipo de Sitio</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="hora_inicial"
                              v-model="form.hora_inicial"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="hora_inicial"
                              >Hora inicial</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="hora_final"
                              v-model="form.hora_final"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="hora_final"
                              >Hora final</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="cargues_hora"
                              v-model="form.cargues_hora"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="cargues_hora"
                              >Cargues por Hora</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="kilometraje_postulacion"
                              v-model="form.kilometraje_postulacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="kilometraje_postulacion"
                              >Kilometraje Postulacion</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tiempo_maximo_cambio"
                              v-model="form.tiempo_maximo_cambio"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tiempo_maximo_cambio"
                              >Tiempo Máximo Cambio</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="promedio_cargue"
                              v-model="form.promedio_cargue"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="promedio_cargue"
                              >Promedio Cargue</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="promedio_cargue_planta"
                              v-model="form.promedio_cargue_planta"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="promedio_cargue_planta"
                              >Promedio Cargue Planta Externa</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('admin.sitios.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "SitioExport", //llegada tambien
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      form: {
        nombre: true,
        sigla: true,
        codigo: true,
        ciudad_id: true,
        departamento_id: true,
        bloque_id: true,
        dirreccion: true,
        latitud: true,
        longitud: true,
        tipo_sitio: true,
        hora_inicial: true,
        hora_final: true,
        cargues_hora: true,
        kilometraje_postulacion: true,
        tiempo_maximo_cambio: true,
        promedio_cargue: true,
        promedio_cargue_planta: true,
      },
      filtros: {
        nombre: null,
        sigla: null,
        codigo: null,
        ciudad_id: null,
        departamento_id: null,
        bloque_id: null,
        tipo_sitio: null,
        estado: null,
      },
      listasForms: {
        ciudades: [],
        departamentos: [],
        bloques: [],
        tipos_sitio: [],
        estados: [],
      },
    };
  },

  methods: {
    async getCiudades() {
      await axios.get("/api/admin/ciudades/lista").then((response) => {
        this.listasForms.ciudades = response.data;
      });
    },

    getSelectCiudades() {
      this.filtros.ciudad = {};
      this.filtros.ciudad_id = null;
      if (this.ciudad) {
        this.filtros.ciudad = this.ciudad;
        this.filtros.ciudad_id = this.ciudad.id;
      }
    },

    async getDepartamentos() {
      await axios.get("/api/admin/departamentos/lista").then((response) => {
        this.listasForms.departamentos = response.data;
      });
    },

    getSelectDepartamentos() {
      this.filtros.departamento = {};
      this.filtros.departamento_id = null;
      if (this.departamento) {
        this.filtros.departamento = this.departamento;
        this.filtros.departamento_id = this.departamento.id;
      }
    },

    async getBloques() {
      await axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },
    getSelectBloques() {
      this.filtros.bloque = {};
      this.filtros.bloque_id = null;
      if (this.bloque) {
        this.filtros.bloque = this.bloque;
        this.filtros.bloque_id = this.bloque.id;
      }
    },

    getTipoSitio() {
      axios.get("/api/lista/18").then((response) => {
        this.listasForms.tipos_sitio = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/sitios/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getCiudades();
    await this.getDepartamentos();
    await this.getBloques();
    await this.getTipoSitio();
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
